.row {
  padding: 15px 30px 15px 30px;
  border-bottom: 1px solid #e6e6e6;
  background: #ffffff;
}
.col {
  color: #ffffff;
  flex: 0 0 300px;
  line-height: 33px;
  font-size: 15px;
  font-weight: 600;
}
.col-login {
  text-align: right;
  font-size: 20px;
}
.nav-link {
  font-size: 16px;
  color: #5f6368;
  font-weight: 500;
  line-height: 16px;
  padding: 20px 10px;
}
.nav-link-active {
  font-weight: bold;
  color: #037cff;
  border-bottom: 2px #1a90ff solid;
}
