.feature-block {
  background: #f1f1f1;
}

.features {
  width: 1024px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.feature {
  width: 50%;
  text-align: center;
  padding: 50px;
  display: flex;
}

.feature-logo {
  margin-right: 25px;
  width: 50px;
}

.feature-content {
  flex: 1;
}

.heading {
  font-size: 30px;
  text-align: left;
  font-weight: 500;
  color: #000;
}

.content {
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
  line-height: 30px;
  color: #8f8f8f;
}

@media screen and (max-width: 800px) {
  .features {
    width: 100%;
  }
  .feature {
    flex: 1;
    width: 100%;
    padding: 20px 50px;
  }
  .feature-logo {
    width: 30px;
    margin-right: 20px;
  }
  .heading {
    font-size: 20px;
  }
}
