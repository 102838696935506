.top-block {
  display: block;
  padding-top: 188px;
  padding-bottom: 200px;
  background-color: #fff;
}
.top-block .heading {
  font-family: Muli, sans-serif;
  max-width: 650px;
  color: #3a3f52;
  font-size: 45px;
  line-height: 55px;
  font-weight: 900;
  display: block;
  margin: auto;
  text-align: center;
}

.top-block .sub-heading {
  max-width: 530px;
  color: #8da2b5;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  display: block;
  margin: 10px auto;
  text-align: center;
}
.top-block .signup-btn-link {
  display: block;
  margin-top: 30px;
}

.top-block .signup-btn {
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.88px;
  background: #1b8aff;
  border-radius: 3px;
  padding: 15px 30px;
  border: 0;
  margin: auto;
  display: block;
  cursor: pointer;
  outline: none;
  height: 50px;
}

@media (max-width: 991px) {
  .top-block .heading {
    display: block;
    max-width: 500px;
    font-size: 35px;
    line-height: 45px;
  }
  .top-block .sub-heading {
    display: block;
    max-width: 450px;
    font-size: 18px;
    line-height: 25px;
  }
}
