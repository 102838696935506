@import url(https://fonts.googleapis.com/css?family=Muli:400,600,700,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f4f4 !important;
}

html body,
button,
input,
select,
textarea {
  font-family: Muli, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
}

.style_loader__13Xul {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Features_feature-block__jzvlj {
  background: #f1f1f1;
}

.Features_features__193qg {
  width: 1024px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.Features_feature__Cv9pJ {
  width: 50%;
  text-align: center;
  padding: 50px;
  display: flex;
}

.Features_feature-logo__2yMjn {
  margin-right: 25px;
  width: 50px;
}

.Features_feature-content__2ve6P {
  flex: 1 1;
}

.Features_heading__o4VAI {
  font-size: 30px;
  text-align: left;
  font-weight: 500;
  color: #000;
}

.Features_content__2GsMX {
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
  line-height: 30px;
  color: #8f8f8f;
}

@media screen and (max-width: 800px) {
  .Features_features__193qg {
    width: 100%;
  }
  .Features_feature__Cv9pJ {
    flex: 1 1;
    width: 100%;
    padding: 20px 50px;
  }
  .Features_feature-logo__2yMjn {
    width: 30px;
    margin-right: 20px;
  }
  .Features_heading__o4VAI {
    font-size: 20px;
  }
}

.Faq_faq-block__3vxtP {
  background: #ffffff;
  padding-top: 1px; /*added to fix (margin:50px of faq-header-section not working*/
  padding-bottom: 1px;
}

.Faq_faq-section__3ZmCd {
  width: 1024px;
  margin: auto;
}

.Faq_faq-header-section__2geno {
  text-align: center;
  margin: 50px;
}

.Faq_faq-heading__1rCnr {
  text-align: center;
  font-weight: 300;
  font-size: 42px;
  line-height: 60px;
}

.Faq_faq-subheading__16AUL {
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  color: #8f8f8f;
}

.Faq_faq__y0prP {
  width: 700px;
  margin: 40px auto;
}

.Faq_faq__y0prP:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Faq_faq-question__2HZc9 {
  font-size: 22px;
  line-height: 3;
  font-weight: 600;
}

.Faq_faq-answer__3rSRF {
  font-size: 19px;
  line-height: 1.8;
  font-weight: 400;
  color: #8f8f8f;
  padding-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .Faq_faq-section__3ZmCd {
    width: 100%;
  }
  .Faq_faq__y0prP {
    width: 100%;
  }
  .Faq_faq-heading__1rCnr {
    font-size: 20px;
  }

  .Faq_faq-subheading__16AUL {
    font-size: 14px;
    line-height: 20px;
  }

  .Faq_faq-question__2HZc9 {
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 40px;
  }

  .Faq_faq-answer__3rSRF {
    font-size: 16px;
    line-height: 1.5;
    padding: 0px 40px 10px 40px;
  }
}

.styles_row__1GBae {
  padding: 15px 30px 15px 30px;
  border-bottom: 1px solid #e6e6e6;
  background: #ffffff;
}
.styles_col__1V774 {
  color: #ffffff;
  flex: 0 0 300px;
  line-height: 33px;
  font-size: 15px;
  font-weight: 600;
}
.styles_col-login__UBLaV {
  text-align: right;
  font-size: 20px;
}
.styles_nav-link__2bWCj {
  font-size: 16px;
  color: #5f6368;
  font-weight: 500;
  line-height: 16px;
  padding: 20px 10px;
}
.styles_nav-link-active__2S-hT {
  font-weight: bold;
  color: #037cff;
  border-bottom: 2px #1a90ff solid;
}

.style_top-block__3eYX1 {
  display: block;
  padding-top: 188px;
  padding-bottom: 200px;
  background-color: #fff;
}
.style_top-block__3eYX1 .style_heading__3-v74 {
  font-family: Muli, sans-serif;
  max-width: 650px;
  color: #3a3f52;
  font-size: 45px;
  line-height: 55px;
  font-weight: 900;
  display: block;
  margin: auto;
  text-align: center;
}

.style_top-block__3eYX1 .style_sub-heading__3tgUJ {
  max-width: 530px;
  color: #8da2b5;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  display: block;
  margin: 10px auto;
  text-align: center;
}
.style_top-block__3eYX1 .style_signup-btn-link__2yUVI {
  display: block;
  margin-top: 30px;
}

.style_top-block__3eYX1 .style_signup-btn__2sqi2 {
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.88px;
  background: #1b8aff;
  border-radius: 3px;
  padding: 15px 30px;
  border: 0;
  margin: auto;
  display: block;
  cursor: pointer;
  outline: none;
  height: 50px;
}

@media (max-width: 991px) {
  .style_top-block__3eYX1 .style_heading__3-v74 {
    display: block;
    max-width: 500px;
    font-size: 35px;
    line-height: 45px;
  }
  .style_top-block__3eYX1 .style_sub-heading__3tgUJ {
    display: block;
    max-width: 450px;
    font-size: 18px;
    line-height: 25px;
  }
}

