.faq-block {
  background: #ffffff;
  padding-top: 1px; /*added to fix (margin:50px of faq-header-section not working*/
  padding-bottom: 1px;
}

.faq-section {
  width: 1024px;
  margin: auto;
}

.faq-header-section {
  text-align: center;
  margin: 50px;
}

.faq-heading {
  text-align: center;
  font-weight: 300;
  font-size: 42px;
  line-height: 60px;
}

.faq-subheading {
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  color: #8f8f8f;
}

.faq {
  width: 700px;
  margin: 40px auto;
}

.faq:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.faq-question {
  font-size: 22px;
  line-height: 3;
  font-weight: 600;
}

.faq-answer {
  font-size: 19px;
  line-height: 1.8;
  font-weight: 400;
  color: #8f8f8f;
  padding-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .faq-section {
    width: 100%;
  }
  .faq {
    width: 100%;
  }
  .faq-heading {
    font-size: 20px;
  }

  .faq-subheading {
    font-size: 14px;
    line-height: 20px;
  }

  .faq-question {
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 40px;
  }

  .faq-answer {
    font-size: 16px;
    line-height: 1.5;
    padding: 0px 40px 10px 40px;
  }
}
